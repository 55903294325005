import React,{useContext} from 'react'

import {
    FormControl,
    Select,
    MenuItem
} from '@material-ui/core'

import searchStyles from '../../pages/search.module.scss'

import { SearchContextDispatch } from '../../context/search-context/search-context'
import { SearchContextState } from '../../context/search-context/search-context'
import * as actionTypes from '../../context/actionTypes'

const PageSizeSelect = () => {
    const state = useContext(SearchContextState)
    const dispatch = useContext(SearchContextDispatch)

    return (
        <FormControl variant="outlined" className={searchStyles.pagerControl}>
            <Select
                onChange={e => dispatch({
                    type: actionTypes.SET_PAGE_SIZE,
                    size: e.target.value
                })}
                value={state.pageSize}
            >
                <MenuItem value={10}>10/lap</MenuItem>
                <MenuItem value={20}>20/lap</MenuItem>
                <MenuItem value={50}>50/lap</MenuItem>
                <MenuItem value={100}>100/lap</MenuItem>
            </Select>
            </FormControl>
    )
}

export default PageSizeSelect
