import React, { useState, useContext, useMemo } from "react"

import { FormControlLabel, Checkbox } from "@material-ui/core"

import { SearchContextDispatch } from "../../../../context/search-context/search-context"

import * as actionTypes from "../../../../context/actionTypes"

import searchStyles from "../../../../pages/search.module.scss"

const LibraryFilterSubitem = props => {
  const {
    name,
    count,
    codes,
    parentChecked,
    setParentCheckbox,
    reset,
    setReset,
    checked
  } = props

  const dispatch = useContext(SearchContextDispatch)

  const [subitemCheckboxChecked, setSubitemCheckboxChecked] = useState(checked)
  

  useMemo(() => {
    if (parentChecked) {
      setSubitemCheckboxChecked(true)
      dispatch({
        type: actionTypes.SET_OFFSET,
        offset: 0
      })

      dispatch({
        type: actionTypes.ADD_LIBRARY_CODE_FILTER,
        filterBool: true,
        codes,
      })
    }

    if (reset && !parentChecked) {
      setSubitemCheckboxChecked(false)
      dispatch({
        type: actionTypes.SET_OFFSET,
        offset: 0
      })

      dispatch({
        type: actionTypes.ADD_LIBRARY_CODE_FILTER,
        filterBool: false,
        codes,
      })
    }
  }, [parentChecked, reset])

  const onSubitemCheckboxChangedHandler = e => {
    if (parentChecked && subitemCheckboxChecked) {
      setParentCheckbox(false)
    }
    if (reset) {
      setReset(false)
    }

    setSubitemCheckboxChecked(e.target.checked)

    dispatch({
      type: actionTypes.SET_OFFSET,
      offset: 0
    })

    dispatch({
      type: actionTypes.ADD_LIBRARY_CODE_FILTER,
      filterBool: e.target.checked,
      codes,
    })
  }

  return (
    <FormControlLabel
      control={
        <Checkbox
          color="default"
          className={searchStyles.searchAdvancedElementsExpDetChkBox}
          checked={subitemCheckboxChecked}
          onChange={onSubitemCheckboxChangedHandler}
        />
      }
      label={
        <div className={searchStyles.searchAdvancedElementsExpDetLabelText}>
          {name} <span>({count})</span>
        </div>
      }
      className={searchStyles.searchAdvancedElementsExpDetLine}
    />
  )
}

export default LibraryFilterSubitem
