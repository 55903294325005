import React, { useContext, useState, useEffect } from "react"

import { FormControlLabel, Checkbox } from "@material-ui/core"
import searchStyles from "../../../pages/search.module.scss"

import { SearchContextDispatch } from "../../../context/search-context/search-context"

import * as actionTypes from "../../../context/actionTypes"

const ElseItem = props => {
  const dispatch = useContext(SearchContextDispatch)

  const { items, reference, shouldVisible, checked } = props

  const [checkboxChecked, setCheckboxChecked] = useState(checked)

  useEffect(() => {
    dispatch({
      type: actionTypes.ON_ADD_TYPE_FILTER,
      filterBool: checkboxChecked,
      filterValue: 'egyéb',
      filterCategory: reference,
      elseItems: items
    })

    dispatch({
      type: actionTypes.SET_OFFSET,
      offset: 0,
    })
  }, [checkboxChecked, dispatch])

  let elseItem = null
  if (items.length !== 0 && shouldVisible) {
    const maxElseItems = items.reduce((sum, { count }) => sum + count, 0)
    elseItem = (
      <FormControlLabel
        control={
          <Checkbox
            color="default"
            className={searchStyles.searchAdvancedElementsExpDetChkBox}
            onChange={e => setCheckboxChecked(e.target.checked)}
            checked={checkboxChecked}
          />
        }
        label={
          <div className={searchStyles.searchAdvancedElementsExpDetLabelText}>
            egyéb <span>({maxElseItems})</span>
          </div>
        }
        className={searchStyles.searchAdvancedElementsExpDetLine}
      />
    )
  }

  return elseItem
}

export default ElseItem
