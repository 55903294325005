import React,{useContext} from "react"

import searchStyles from '../../../pages/search.module.scss'

import * as actionTypes from '../../../context/actionTypes'
import {SearchContextDispatch} from '../../../context/search-context/search-context'

const VisualSizeChange = (props) => {
  const {label, panelRef} = props

  const dispatch = useContext(SearchContextDispatch)

  return (
    <span
      style={{ cursor: "pointer" }}
      onClick={() => 
        dispatch({
          type: actionTypes.ON_CHANGE_FILTER_VISUAL,
          visual: panelRef
       })}
      className={searchStyles.showAllButton}
    >
      {label}
    </span>
  )
}

export default VisualSizeChange
