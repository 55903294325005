export default (queryJson, advancedSearchUrlData, allLibraryMappingCsv) => {
  queryJson = {
    bool: {
      must: [],
      filter: [],
    },
  }

  let yearAdvancedSearch = {
    publishYear: null,
    afterYear: false,
    previousYear: false,
    fromYear: null,
    toYear: null,
  }

  advancedSearchUrlData.map(urlData => {
    const rawSplit = urlData.split("=")

    const dataCategory = rawSplit[0] // author, title, doctype etc...
    const data = rawSplit[1] // arany jános, toldi, könyv, etc...

    const pushMust = field => {
      if (data) {
        return queryJson.bool.must.push({
          match: {
            [field]: {
              query: data,
              fuzziness: "auto",
            },
          },
        })
      }
    }
    switch (dataCategory) {
      case "languagecode": {
        if (data === "all") return

        return pushMust("languageCode")
      }
      case "librarytype": {
        if (data === "all") return
        if (!data) return
        let groupByLibraryTypeAndCodes = []
        allLibraryMappingCsv.nodes.map(node => {
          const findCategory = groupByLibraryTypeAndCodes.find(
            library => library.category === node.category
          )
          if (findCategory) {
            return findCategory.codes.push(node.code)
          }

          return groupByLibraryTypeAndCodes.push({
            category: node.category,
            codes: [node.code],
          })
        })

        const findSearchableCategory = groupByLibraryTypeAndCodes.find(
          libraryType =>
            libraryType.category.toLowerCase().trim() ===
            data.toLowerCase().trim()
        )
        if (findSearchableCategory) {
          const shouldTerms = []
          findSearchableCategory.codes.map(code => {
            shouldTerms.push({
              term: {
                libraryCode: code,
              },
            })

            return queryJson.bool.must.push({
              bool: {
                should: shouldTerms,
              },
            })
          })
        }
        return
      }
      case "type": {
        if (data === "all") return

        return pushMust("type")
      }
      case "placeofappearance":
        return pushMust("placeOfAppearance")
      case "warehousesign":
        return pushMust("warehouseSign")
      case "librarycode":
        return pushMust("libraryCode")
      case "afteryear":
        return (yearAdvancedSearch.afterYear = data === "true")
      case "previousyear":
        return (yearAdvancedSearch.previousYear = data === "true")
      case "publishyear":
        return (yearAdvancedSearch.publishYear = data)
      case "fromyear":
        return (yearAdvancedSearch.fromYear = data)
      case "toyear":
        return (yearAdvancedSearch.toYear = data)
      default:
        return pushMust(dataCategory)
    }
  })

  const {
    publishYear,
    fromYear,
    toYear,
    afterYear,
    previousYear,
  } = yearAdvancedSearch

  if (fromYear && toYear) {
    queryJson.bool.must.push({
      range: {
        dateOfAppearance: {
          gte: fromYear,
          lte: toYear,
        },
      },
    })
  } else if (afterYear || previousYear) {
    if(publishYear.trim() === '' || !publishYear) return queryJson

    if (afterYear) {
      queryJson.bool.must.push({
        range: {
          dateOfAppearance: {
            gte: publishYear,
          },
        },
      })
    } else {
      queryJson.bool.must.push({
        range: {
          dateOfAppearance: {
            lte: publishYear,
          },
        },
      })
    }
  }
  else if(!afterYear && !previousYear && publishYear && publishYear.trim() !== '') {
    queryJson.bool.must.push({
      match: {
        dateOfAppearance: publishYear
      }
    })
  }

  return queryJson
}