import React, { useState, useContext } from "react"

import {
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core"
import sort from "fast-sort"

import ExpandMoreIcon from "@material-ui/icons/ExpandMore"

import {SearchContextState} from '../../../context/search-context/search-context'

import searchStyles from "../../../pages/search.module.scss"
import LibraryFilterSubitem from "./LibraryFilterSubitem/LibraryFilterSubitem"

const LibraryFilterItem = props => {
  const { category, checked } = props

  const state = useContext(SearchContextState)
  const {libraryCodesFilter} = state

  const [filterItemCheckboxChecked,setFilterItemCheckboxChecked] = useState(checked)
  const [resetSubitemCheckboxes, setResetSubitemCheckboxes] = useState(false)

  let defaultExapanded = false;

  const unorderedPanelItems = category.connectedLibraryCodes.map(subitem => {
    const sumCodesCount = subitem.codes.reduce(
      (prev, { count }) => prev + count,
      0
    )

    return {
      name: subitem.name,
      count: sumCodesCount,
      codes: subitem.codes,
    }
  })

  const orderedPanelItems = sort(unorderedPanelItems).desc(item => item.count)

  const subItemElements = orderedPanelItems.map(panelItem => {
    if (panelItem.count === 0) return null

    const match = libraryCodesFilter.find(item => panelItem.codes[0].code === item)
    if(match) defaultExapanded = true

    return (
      <LibraryFilterSubitem
        name={panelItem.name}
        count={panelItem.count}
        codes={panelItem.codes}
        parentChecked={filterItemCheckboxChecked}
        setParentCheckbox={setFilterItemCheckboxChecked}
        reset={resetSubitemCheckboxes}
        setReset={setResetSubitemCheckboxes}
        checked={match ? true : false}
      />
    )
  })

  const onCheckboxChangedHandler = checked => {
    if(!checked) {
        setResetSubitemCheckboxes(true)
    }

    setFilterItemCheckboxChecked(checked)
  }

  return (
    <ExpansionPanel
      className={searchStyles.searchAdvancedElementsExpSub}
      defaultExpanded={defaultExapanded}
    >
      <ExpansionPanelSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel6b-content"
        id="panel6b-header"
        className={searchStyles.searchAdvancedElementsExpSum}
      >
        <FormControlLabel
          control={
            <Checkbox
              color="default"
              className={searchStyles.searchAdvancedElementsExpDetChkBox}
              checked={filterItemCheckboxChecked}
              onChange={e => onCheckboxChangedHandler(e.target.checked)}
            />
          }
          label={
            <div className={searchStyles.searchAdvancedElementsExpDetLabelText}>
              {category.name}
            </div>
          }
          className={searchStyles.searchAdvancedElementsExpDetLine}
        />
      </ExpansionPanelSummary>
      <ExpansionPanelDetails
        className={searchStyles.searchAdvancedElementsExpDet}
      >
        {subItemElements}
      </ExpansionPanelDetails>
    </ExpansionPanel>
  )
}

export default LibraryFilterItem
