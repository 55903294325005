import { useStaticQuery, graphql } from "gatsby"
import advancedSearch from './advancedSearch'

export default (state, props, dispatch, actionTypes) => {
  const GET_LIBRARY_DATA_QUERY = graphql`
    query getLanguageCodesForAdvancedSearch {
      allLibraryMappingCsv {
        nodes {
          category
          code
        }
      }
    }
  `

  const staticLibraryData = useStaticQuery(GET_LIBRARY_DATA_QUERY)
  const { allLibraryMappingCsv } = staticLibraryData

  const { location } = state
  const {
    matchYear,
    fromYearToYear,
    fromYear,
    toYear,
    publishedYear,
    publishedYearPrevious,
    publishedYearAfter,
    yearFilterEnabled,
  } = state
  const { keyword, emptySearch } = state

  const { libraryCodesFilter } = state

  let queryJson = null

  if (keyword === "") {
    const urlParams = decodeURI(props.location.search.split("?")[1])
    const advancedSearchUrlData = urlParams.split("&")
    if (
      advancedSearchUrlData[0].split("=")[0] !== "keyword" &&
      advancedSearchUrlData[0] !== "undefined" &&
      !emptySearch
    ) {
      //advanced search
      queryJson = advancedSearch(queryJson, advancedSearchUrlData, allLibraryMappingCsv)
    } else {
      const urlKeyword = decodeURI(props.location.search.split("=")[1])

      if (!urlKeyword || urlKeyword === "undefined" || emptySearch || urlKeyword.trim() === "") {
        queryJson = {
          bool: {
            must: [
              {
                match_all: {},
              },
            ],
            filter: [],
          },
        }
      } else {
        dispatch({
          type: actionTypes.ON_SET_KEYWORD_FROM_URL,
          keyword: urlKeyword,
        })
      }
    }
  } else {
    queryJson = {
      bool: {
        must: [
          {
            multi_match: {
              query: keyword,
              type: "cross_fields",
              operator: "and",
            },
          },
        ],
        filter: [],
      },
    }
  }

  if (
    state.typeFilters.length !== 0 ||
    state.authorFilters.length !== 0 ||
    state.languageFilters.length !== 0
  ) {
    if (state.typeFilters.length > 0) {
      const shouldTerms = []

      state.typeFilters.map(type => {
        return shouldTerms.push({
          term: {
            type,
          },
        })
      })

      queryJson.bool.filter.push({
        bool: {
          should: shouldTerms,
        },
      })
    }

    if (state.authorFilters.length > 0) {
      const shouldTerms = []

      state.authorFilters.map(author => {
        return shouldTerms.push({
          term: {
            "author.keyword": author,
          },
        })
      })

      queryJson.bool.filter.push({
        bool: {
          should: shouldTerms,
        },
      })
    }

    if (state.languageFilters.length > 0) {
      const shouldTerms = []

      state.languageFilters.map(language => {
        return shouldTerms.push({
          term: {
            languageCode: language,
          },
        })
      })

      queryJson.bool.filter.push({
        bool: {
          should: shouldTerms,
        },
      })
    }
  }

  if (location.trim() !== "") {
    queryJson.bool.filter.push({
      bool: {
        should: [
          {
            match: {
              placeOfAppearance: location,
            },
          },
        ],
      },
    })
  }

  if (yearFilterEnabled) {
    if (matchYear) {
      queryJson.bool.filter.push({
        bool: {
          should: [
            {
              match: {
                dateOfAppearance: publishedYear,
              },
            },
          ],
        },
      })
    } else {
      if (fromYearToYear && fromYear && toYear) {
        queryJson.bool.filter.push({
          range: {
            dateOfAppearance: {
              gte: fromYear,
              lte: toYear,
            },
          },
        })
      } else {
        if (publishedYearAfter && publishedYearPrevious) {
        } else if (publishedYearAfter) {
          queryJson.bool.filter.push({
            range: {
              dateOfAppearance: {
                gte: publishedYear,
              },
            },
          })
        } else {
          queryJson.bool.filter.push({
            range: {
              dateOfAppearance: {
                lte: publishedYear,
              },
            },
          })
        }
      }
    }
  }

  if (libraryCodesFilter.length !== 0) {
    const shouldTerms = []

    libraryCodesFilter.map(libraryCode => {
      shouldTerms.push({
        term: {
          libraryCode,
        },
      })
    })

    queryJson.bool.filter.push({
      bool: {
        should: shouldTerms,
      },
    })
  }

  return queryJson
}
