import React, { useContext, useState, useEffect } from "react"

import searchStyles from "../../../pages/search.module.scss"

import { FormControlLabel, Checkbox } from "@material-ui/core"

import { SearchContextDispatch } from "../../../context/search-context/search-context"

import * as actionTypes from "../../../context/actionTypes"

const TypeFilterItem = React.memo(function TypeFilterItem(props) {
  const { title, count, filterCategory, rawKey, checked } = props

  const [checkboxChecked, setCheckboxChecked] = useState(checked)

  const dispatch = useContext(SearchContextDispatch)

  useEffect(() => {
    dispatch({
      type: actionTypes.ON_ADD_TYPE_FILTER,
      filterBool: checkboxChecked,
      filterValue: rawKey,
      filterCategory,
    })

    dispatch({
      type: actionTypes.SET_OFFSET,
      offset: 0,
    })
  },[checkboxChecked, dispatch])

  return (
    <FormControlLabel
      control={
        <Checkbox
          color="default"
          className={searchStyles.searchAdvancedElementsExpDetChkBox}
          onChange={e => setCheckboxChecked(e.target.checked)}
          checked={checkboxChecked}
        />
      }
      label={
        <div className={searchStyles.searchAdvancedElementsExpDetLabelText}>
          {title} <span>({count})</span>
        </div>
      }
      className={searchStyles.searchAdvancedElementsExpDetLine}
    />
  )
})

export default TypeFilterItem
