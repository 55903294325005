import React, { useContext } from "react"

import { graphql, useStaticQuery } from "gatsby"

import {
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
} from "@material-ui/core"

import ExpandMoreIcon from "@material-ui/icons/ExpandMore"

import { SearchContextState } from "../../context/search-context/search-context"

import searchStyles from "../../pages/search.module.scss"
import LibraryFilterItem from "./LibraryFilterItem/LibraryFilterItem"
import LibraryElseFilterItem from "./LibraryElseFilterItem/LibraryElseFilterItem"

const LibraryFilterPanel = props => {
  const { bucket } = props
  const state = useContext(SearchContextState)
  const { libraryCodesFilter } = state

  let categories = []

  const LIBRARY_CSV_DATA_QUERY = graphql`
    query GetLibraryCsvDataQuery {
      allLibraryMappingCsv {
        edges {
          node {
            code
            category
            name
          }
        }
      }
    }
  `
  const libraryMapping = useStaticQuery(LIBRARY_CSV_DATA_QUERY)

  libraryMapping.allLibraryMappingCsv.edges.map(node => {
    const findCategory = categories.find(
      category => category.name === node.node.category
    )

    if (!findCategory) {
      categories.push({
        name: node.node.category,
        connectedLibraryCodes: [
          {
            name: node.node.name,
            codes: [
              {
                code: node.node.code,
                count: 0,
              },
            ],
          },
        ],
      })
    } else {
      const findCodes = findCategory.connectedLibraryCodes.find(
        connectedCode => connectedCode.name === node.node.name
      )
      if (!findCodes) {
        findCategory.connectedLibraryCodes.push({
          name: node.node.name,
          codes: [
            {
              code: node.node.code,
              count: 0,
            },
          ],
        })
      } else {
        findCodes.codes.push({
          code: node.node.code,
          count: 0,
        })
      }
    }
  })

  const elseItems = []
  bucket.map(aggData => {
    const findCategory = categories.find(category => {
      return category.connectedLibraryCodes.find(connectedLibraryCodes => {
        return connectedLibraryCodes.codes.find(
          code => code.code === aggData.key
        )
      })
    })

    if (findCategory) {
      findCategory.connectedLibraryCodes.find(connectedLibraryCode => {
        const code = connectedLibraryCode.codes.find(
          code => code.code === aggData.key
        )
        if (code) {
          code.count = aggData.doc_count
        }
      })
    } else {
      elseItems.push({
        code: aggData.key,
        count: aggData.doc_count,
      })
    }
  })

  const renderLibraryFilterItems = categories.map((category, i) => {
    const summOffCategory = category.connectedLibraryCodes.reduce(
      (prev, cur) => {
        const summOfCodes = cur.codes.reduce(
          (codePrev, codeCur) => codePrev + codeCur.count,
          0
        )
        return prev + summOfCodes
      },
      0
    )

    if (summOffCategory === 0) return null

    let countMatch = 0
    category.connectedLibraryCodes.map(connectedLibraryCode => {
      connectedLibraryCode.codes.map(subCode => {
        const match = libraryCodesFilter.find(filter => subCode.code === filter)
        if (match) countMatch++
      })
    })

    const countCategoryCodes = category.connectedLibraryCodes.reduce(
      (prev, cur) => {
        let countCurrentCategoryCodes = 0
        cur.codes.map(code => {
          if (code.count > 0) countCurrentCategoryCodes++
        })

        return prev + countCurrentCategoryCodes
      },
      0
    )

    const checked = countMatch === countCategoryCodes
    return <LibraryFilterItem category={category} checked={checked} key={i} />
  })

  let elseItemsMatch = false
  if(elseItems.length !== 0 && elseItems) {
    elseItemsMatch = libraryCodesFilter.find(item => item === elseItems[0].code)
  }

  return (
    <ExpansionPanel
      className={searchStyles.searchAdvancedElementsExp}
      defaultExpanded={true}
      rounded={0}
    >
      <ExpansionPanelSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel6a-content"
        id="panel6a-header"
        className={searchStyles.searchAdvancedElementsExpSum}
      >
        Könyvtár
      </ExpansionPanelSummary>
      <ExpansionPanelDetails
        className={searchStyles.searchAdvancedElementsExpDet}
      >
        {renderLibraryFilterItems}
        <LibraryElseFilterItem elseItems={elseItems} checked={elseItemsMatch ? true : false}/>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  )
}

export default LibraryFilterPanel
