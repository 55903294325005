import React,{useState, useContext, useMemo} from 'react'
import useDidMountEffect from '../../hooks/useDidMountEffect'

import {
    ExpansionPanel,
    ExpansionPanelSummary,
    ExpansionPanelDetails,
    Grid,
    TextField,
    FormControlLabel,
    Checkbox
} from '@material-ui/core'
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import searchStyles from '../../pages/search.module.scss'


import * as actionTypes from '../../context/actionTypes'
import { SearchContextDispatch } from '../../context/search-context/search-context'
import { SearchContextState } from '../../context/search-context/search-context'

const PublishYearFilterPanel = () => {
    /*
        yearFilterEnabled: false,
        fromYearToYear: true,
        matchYear: false,
        fromYear: 0,
        toYear: 0,
        publishedYear: 0,
        publishedYearPrevious: false,
        publishedYearAfter: false
    */

    
    const dispatch = useContext(SearchContextDispatch)
    const state = useContext(SearchContextState)

    const { typeBucket, authorBucket, languageBucket } = state

    const [fromYear, setFromYear] = useState('')
    const [toYear, setToYear] = useState('')
    
    const [publishedYear, setPublishedYear] = useState('');

    const [previous, setPrevious] = useState('')
    const [after, setAfter] = useState('')

    const [timer, setTimer] = useState(0)
    const [error, setError] = useState(false)

    useMemo(() => {
        //Reset panel when theres a new search

        setFromYear(`${state.fromYear}`)
        setToYear(`${state.toYear}`)
        setPublishedYear(`${state.publishedYear}`)
        setPrevious(state.publishedYearPrevious)
        setAfter(state.publishedYearAfter)
        setError(false)
        setTimer(0)
    } , [typeBucket, authorBucket, languageBucket])

    useDidMountEffect(() => {
        clearTimeout(timer)
        if(error) return

        setTimer(setTimeout(() => {
            dispatch({
                type: actionTypes.SET_OFFSET,
                offset: 0
            })

            if(previous || after){
                if(publishedYear === '' && fromYear && toYear) {
                    return dispatch({
                        type: actionTypes.ON_FILTER_FOR_YEAR_TO_YEAR,
                        fromYear: parseInt(fromYear),
                        toYear: parseInt(toYear)
                    })
                } 

                dispatch({
                    type: actionTypes.ON_FILTER_FOR_EXACT_YEAR,
                    publishedYear: parseInt(publishedYear),
                    previous,
                    after
                })
            }
            else if(!previous && !after && publishedYear !== '') {
                dispatch({
                    type: actionTypes.ON_FILTER_FOR_MATCH_YEAR,
                    publishedYear
                })
            }
            else {                
                dispatch({
                    type: actionTypes.ON_FILTER_FOR_YEAR_TO_YEAR,
                    fromYear: parseInt(fromYear),
                    toYear: parseInt(toYear)
                })
            }
        }, 2000))
    }, [previous, after, fromYear, toYear, publishedYear])


    const onPreviousCheckboxChangedHandler = e => {
        if(previous){
            setPrevious(false)
        }
        else{
            setAfter(!e.target.checked)
            setPrevious(e.target.checked)
        }
    }

    const onAfterCheckboxChangedHandler = e => {
        if(after){
            setAfter(false)
        }
        else{
            setAfter(e.target.checked)
            setPrevious(!e.target.checked)
        }
    }

    const onFromYearInputChangedHandler = e => {
        const val = parseInt(e.target.value)

        const regex = new RegExp(/[0-9]|\./) 
        if(!regex.test(e.target.value)) return
        if(val > parseInt(toYear)) setError(true)
        else setError(false)

        if(val < 1000) setError(true)
        else setError(false)

        setFromYear(e.target.value)
    }

    const onToYearInputChangedHandler = e => {
        const val = parseInt(e.target.value)

        const regex = new RegExp(/[0-9]|\./) 
        if(!regex.test(e.target.value)) return      
        if(val < parseInt(fromYear)) setError(true)
        else setError(false)

        if(val < 1000) setError(true)
        else setError(false)

        setToYear(e.target.value)
    }

    const onPublishedYearChangedHandler = e => {
        if(e.target.value === '') return setPublishedYear('')

        const val = parseInt(e.target.value)

        const regex = new RegExp(/[0-9]|\./)
        if(!regex.test(e.target.value)) return 
        if(val > 9999) return
        if(val < 0) return

        setPublishedYear(e.target.value)
    }

    return (
        <ExpansionPanel className={searchStyles.searchAdvancedElementsExp} defaultExpanded={true}>
                <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel3a-content"
                    id="panel3a-header"
                    className={searchStyles.searchAdvancedElementsExpSum}
                >
                    Megjelenés éve
                </ExpansionPanelSummary>
                <ExpansionPanelDetails className={searchStyles.searchAdvancedElementsExpDet}>
                    <Grid container spacing={0} className={searchStyles.appearanceDateContainer}>
                    <Grid item xs={6} className={searchStyles.appearanceDateContainerLeft}>
                        <span>Évtől</span>
                        <form>
                        {
                            error
                            ?
                            <TextField
                                error
                                id="outlined-number"
                                value={fromYear}
                                onChange={onFromYearInputChangedHandler}
                                type="number"
                                margin="null"
                                variant="outlined"
                                className={searchStyles.dateFromField}
                            />
                            :
                            <TextField
                                id="outlined-number"
                                value={fromYear}
                                onChange={onFromYearInputChangedHandler}
                                type="number"
                                margin="null"
                                variant="outlined"
                                className={searchStyles.dateFromField}
                            />
                        }                        
                        </form>
                    </Grid>
                    <Grid item xs={6} className={searchStyles.appearanceDateContainerRight}>
                        <span>Évig</span>
                        <form>
                        {
                            error
                            ?
                            <TextField
                                error
                                id="outlined-number"
                                value={toYear}
                                onChange={onToYearInputChangedHandler}
                                type="number"
                                margin="none"
                                variant="outlined"
                                className={searchStyles.dateToField}
                            />
                            :
                            <TextField
                                id="outlined-number"
                                value={toYear}
                                onChange={onToYearInputChangedHandler}
                                type="number"
                                margin="none"
                                variant="outlined"
                                className={searchStyles.dateToField}
                            />
                        }   
                        </form>
                    </Grid>
                    <Grid item xs={12} className={searchStyles.appearanceDateContainerFull}>
                        <span>Megjelenés éve</span>
                        <form>
                        <TextField
                            id="place"
                            className={searchStyles.textField}
                            margin="none"
                            variant="outlined"
                            onChange={onPublishedYearChangedHandler}
                            value={publishedYear}
                            type='search'
                        />
                        </form>
                    </Grid>
                    <Grid item xs={6} className={searchStyles.appearanceDateContainerRight}>
                        <FormControlLabel
                            control={
                            <Checkbox
                                color="default"
                                className={searchStyles.searchAdvancedElementsExpDetChkBox}
                                onChange={onPreviousCheckboxChangedHandler}
                                checked={previous}
                            />
                            }
                            label={
                            <div className={searchStyles.searchAdvancedElementsExpDetLabelText}>Előtt</div>
                            }
                            className={
                            searchStyles.searchAdvancedElementsExpDetLine
                            }
                        />
                    </Grid>
                    <Grid item xs={6} className={searchStyles.appearanceDateContainerRight}>
                        <FormControlLabel
                            control={
                            <Checkbox
                                color="default"
                                className={searchStyles.searchAdvancedElementsExpDetChkBox}
                                onChange={onAfterCheckboxChangedHandler}
                                checked={after}
                            />
                            }
                            label={
                            <div className={searchStyles.searchAdvancedElementsExpDetLabelText}>Után</div>
                            }
                            className={
                            searchStyles.searchAdvancedElementsExpDetLine
                            }
                        />
                    </Grid>
                    </Grid>
                </ExpansionPanelDetails>
                </ExpansionPanel>
    )
}

export default PublishYearFilterPanel
