import React,{useState, useContext} from 'react'

import {
    FormControl,
    Button
} from '@material-ui/core'
import searchStyles from '../../pages/search.module.scss'

import {
    KeyboardArrowUp,
    KeyboardArrowDown
} from '@material-ui/icons'

import { SearchContextDispatch } from '../../context/search-context/search-context'
import * as actionTypes from '../../context/actionTypes'

const AbcSort = props => {
    const {sortTitle, sortRef, active} = props

    const [value, setValue] = useState(true)

    const dispatch = useContext(SearchContextDispatch)

    const onButtonClickedHandler = () => {
        setValue(!value)

        dispatch({
            type: actionTypes.SET_ABC_SORT,
            value: value,
            title: sortRef
        })
    }

    //Arrow swap
    let arrowElement = null
    if(value){
        arrowElement = <KeyboardArrowDown />
    }
    else{
        arrowElement = <KeyboardArrowUp />
    }

    //Active button element SWAP
    let buttonElement = null
    if(active){
        //Active button
        buttonElement = <Button
                            onClick={onButtonClickedHandler}
                            className={searchStyles.authorSorterSelectActive}

                        >
                            {sortTitle} {arrowElement}
                        </Button>
    }
    else{
        //Not active button
        buttonElement = <Button
                            onClick={onButtonClickedHandler}
                            className={searchStyles.authorSorterSelect}
                        >
                            {sortTitle} {arrowElement}
                        </Button>
    }

    return (
        <FormControl className={[searchStyles.authorSorterControl, 'sorterController']}>
            {buttonElement}
            {/* <Select
                className={searchStyles.authorSorterSelect}
                value={value}
                onChange={onSelectInputChangedHandler}
                disabled
            >
                <MenuItem value={1}>{sortTitle}</MenuItem>
                {/* <MenuItem value={1}>{sortTitle} </MenuItem>
                <MenuItem value={2}>{sortTitle} </MenuItem>
            </Select> */}
        </FormControl>
    )
}

export default AbcSort
