import React, { useContext } from "react"

import {
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
} from "@material-ui/core"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"

import searchStyles from "../../pages/search.module.scss"

import TypeFilterItem from "./TypeFilterItem/TypeFilterItem"
import ElseItem from "./ElseItem/ElseItem"
import VisualSizeChange from './VisualSizeChange/VisualSizeChange'

import { SearchContextState } from "../../context/search-context/search-context"

import { graphql, useStaticQuery } from "gatsby"

const TypeFilterPanel = React.memo(function TypeFilterPanel(props) {
  const { panelTitle, panelRef, bucket } = props

  const state = useContext(SearchContextState)
  const {
    showAllTypeFilter,
    showAllAuthorFilter,
    showAllLanguageFilter,
    typeFilters,
    authorFilters,
    languageFilters,
  } = state

  const elseItems = []
  let shouldVisible = false

  let countRenders = {
    language: 0,
    type: 0,
    author: 0,
  }

  let resizeText = 'Lista szűkítése';

  const GET_LANGUAGE_CODE_MAPPING_QUERY = graphql`
    query MyQuery {
      allLanguageMappingCsv {
        nodes {
          key
          hun
          eng
        }
      }

      allDoctypeMappingCsv {
        nodes {
          hun
        }
      }
    }
  `

  const dataMappings = useStaticQuery(GET_LANGUAGE_CODE_MAPPING_QUERY)
  const { allLanguageMappingCsv, allDoctypeMappingCsv } = dataMappings

  const checkData = data => {
    switch (panelRef) {
      case "type": {
        const matched = allDoctypeMappingCsv.nodes.find(
          node => node.hun === data.key
        )
        if (!matched) {
          elseItems.push({
            key: data.key,
            count: data.doc_count,
          })
          return
        }

        countRenders.type++

        const match = typeFilters.find(item => item === data.key)

        return (
          <TypeFilterItem
            key={data.key}
            title={data.key}
            rawKey={data.key}
            filterCategory={panelRef}
            count={data.doc_count}
            checked={match ? true : false}
          />
        )
      }
      case "language": {
        const matched = allLanguageMappingCsv.nodes.find(
          node => node.key === data.key
        )
        if (!matched) {
          elseItems.push({
            key: data.key,
            count: data.doc_count,
          })
          return
        }

        const match = languageFilters.find(item => item === data.key)

        countRenders.language++
        return (
          <TypeFilterItem
            key={data.key}
            title={matched.hun !== "" ? matched.hun : matched.eng}
            rawKey={data.key}
            filterCategory={panelRef}
            count={data.doc_count}
            checked={match ? true : false}
          />
        )
      }
      default:
        countRenders.author++

        const match = authorFilters.find(item => item === data.key)

        return (
          <TypeFilterItem
            key={data.key}
            title={data.key}
            rawKey={data.key}
            filterCategory={panelRef}
            count={data.doc_count}
            checked={match ? true : false}
          />
        )
    }
  }

  const bucketDataRenderer = (currentFilter, data, index) => {
    if (currentFilter) {
      if (index === bucket.length - 1) {
        shouldVisible = true
        return checkData(data, currentFilter)
      }

      shouldVisible = false
      return checkData(data, currentFilter)
    } else if (countRenders[panelRef] < 5 && !currentFilter) {
      if (countRenders[panelRef] === bucket.length - 1) {
        shouldVisible = true
        return checkData(data, currentFilter)
      }
      shouldVisible = false
      return checkData(data, currentFilter)
    } else if (countRenders[panelRef] === 5 && !currentFilter) {
      countRenders[panelRef]++
      resizeText = `Összes ${panelTitle.toLowerCase()}`
    } else countRenders[panelRef]++
  }
  
  let elseItemChecked = false

  const renderBucketData = bucket.map((data, i) => {
    let elseMatch = false
    switch (panelRef) {
      case "type":
        elseMatch = typeFilters.find(item => item === elseItems[0])
        elseItemChecked = elseMatch ? true : false
        return bucketDataRenderer(showAllTypeFilter, data, i)
      case "author":
        return bucketDataRenderer(showAllAuthorFilter, data, i)
      case "language":
        elseMatch = typeFilters.find(item => item === elseItems[0])
        elseItemChecked = elseMatch ? true : false
        return bucketDataRenderer(showAllLanguageFilter, data, i)
      default: return console.log('should not be here')
    }
  })

  return (
    <ExpansionPanel
      className={searchStyles.searchAdvancedElementsExp}
      defaultExpanded={true}
    >
      <ExpansionPanelSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
        className={searchStyles.searchAdvancedElementsExpSum}
      >
        {panelTitle}
      </ExpansionPanelSummary>
      <ExpansionPanelDetails
        className={searchStyles.searchAdvancedElementsExpDet}
      >
        {renderBucketData}

        <ElseItem
          items={elseItems}
          shouldVisible={shouldVisible}
          reference={panelRef}
          checked={elseItemChecked}
        />
        
        {renderBucketData.length > 5 ?
          <VisualSizeChange 
            panelRef={panelRef}
            label={resizeText}
          />
          :
          null
        }
      </ExpansionPanelDetails>
    </ExpansionPanel>
  )
})

export default TypeFilterPanel
