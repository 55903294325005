import React, { useContext, useState, useEffect } from "react"

import searchStyles from "../../../pages/search.module.scss"

import { FormControlLabel, Checkbox, Paper } from "@material-ui/core"

import { SearchContextDispatch } from "../../../context/search-context/search-context"
import * as actionTypes from "../../../context/actionTypes"

const LibraryElseFilterItem = props => {
  const dispatch = useContext(SearchContextDispatch)
  const { elseItems, checked } = props

  const [checkboxChecked,setCheckboxChecked] = useState(checked)

  useEffect(() => {
    dispatch({
      type: actionTypes.ADD_LIBRARY_ELSE_FILTER,
      codes: elseItems,
      filterBool: checkboxChecked,
    })
  },[checkboxChecked, dispatch])

  if (elseItems.length === 0) return null

  const summOfElseItems = elseItems.reduce((prev, { count }) => prev + count, 0)


  return (
    <FormControlLabel
      control={
        <Checkbox
          color="default"
          className={searchStyles.searchAdvancedElementsExpDetChkBox}
          checked={checkboxChecked}
          onChange={e => setCheckboxChecked(e.target.checked)}
        />      
      }
      label={
        <div className={searchStyles.searchAdvancedElementsExpDetLabelText}>
          egyéb <span>({summOfElseItems})</span>
        </div>
      }
      className={[searchStyles.searchAdvancedElementsExpDetLine,  searchStyles.topLine].join(' ')}
    />
  )
}

export default LibraryElseFilterItem
