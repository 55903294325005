import React, { useContext, useState, useEffect, useMemo, useRef } from "react"

import { SearchContextState } from "../context/search-context/search-context"
import { SearchContextDispatch } from "../context/search-context/search-context"

import { useQuery } from "@apollo/react-hooks"
import gql from "graphql-tag"

import { Link, graphql, useStaticQuery } from "gatsby"

import {
  Grid,
  Box,
  Container,
  Breadcrumbs,
  CircularProgress,
  Snackbar,
} from "@material-ui/core"
import { spacing } from "@material-ui/styles"
import Pagination from "material-ui-flat-pagination"

import SubPageLayout from "../layouts/subpage_layout"

import styles from "./index.module.scss"
import searchStyles from "./search.module.scss"
import "../styles/basic_style.scss"

import Head from "../components/head"
import TypeFilterPanel from "../components/TypeFilterPanel/typeFilterPanel"
import * as actionTypes from "../context/actionTypes"
import LibraryFilterPanel from "../components/LibraryFilterPanel/LibraryFilterPanel"
import PublishYearFilterPanel from "../components/PublishYearFilterPanel/PublishYearFilterPanel"
import PublishLocationPanel from "../components/PublishLocationPanel/PublishLocationPanel"
import AbcSort from "../components/AbcSort/AbcSort"
import PageSizeSelect from "../components/PageSizeSelect/PageSizeSelect"
import ErrorSnackbar from "../components/ErrorSnackbar/ErrorSnackbar"

import queryFormer from "../utils/queryFormer"
import scrollToRef from "../utils/scrollToRef"
import ListElement from "../components/ListElement/ListElement"

const STATIC_QUERY = graphql`
  query GetStaticDataForElementListPage {
    allMarkdownRemark {
      edges {
        node {
          internal {
            content
          }
          fields {
            slug
          }
        }
      }
    }
  }
`

const MAIN_ELASTIC_QUERY = gql`
  query Search($queryJson: JSON, $sortJson: JSON, $size: Int, $page: Int) {
    elasticDocuments(
      query: $queryJson
      sort: $sortJson
      size: $size
      from: $page
      aggs: {
        type: { terms: { field: "type", size: 10000 } }
        author: { terms: { field: "author.keyword", size: 100 } }
        languageCode: { terms: { field: "languageCode", size: 10000 } }
        libraryCode: { terms: { field: "libraryCode", size: 10000 } }
      }
    ) {
      documentsPayload
    }
  }
`

const ElemetListPage = React.memo(function ElemetListPage(props) {
  const state = useContext(SearchContextState)
  const dispatch = useContext(SearchContextDispatch)

  const staticData = useStaticQuery(STATIC_QUERY)
  const { allMarkdownRemark } = staticData

  const matchPageEdge = allMarkdownRemark.edges.find(
    edge => edge.node.fields.slug === "element_list"
  )
  const staticTextForOverflowHits = matchPageEdge.node.internal.content
  const [
    isShowOverflowWarningVisible,
    setIsShowOverflowWarningVisible,
  ] = useState(false)

  const { typeBucket, authorBucket, languageBucket } = state
  const { libraryCodesBucket } = state
  const { orderBy } = state

  const { pageSize, offset } = state

  const [pagination, setPagination] = useState(null)
  const [hitsCountElement, setHitsCountElement] = useState(null)
  const [searchResultElements, setSearchResultElements] = useState(null)
  const [errorModal, setErrorModal] = useState({
    open: false,
    errorMessage: null,
  })

  const topContainer = useRef(null)

  const closeErrorModalHandler = (event, reason) => {
    if (reason === "clickaway") {
      return
    }
    setErrorModal({ ...errorModal, open: false })
  }

  const queryJson = queryFormer(state, props, dispatch, actionTypes)

  const sortJson = []
  if (orderBy) {
    sortJson.push({
      [orderBy.key]: {
        order: orderBy.order,
      },
    })
  }

  // console.log(JSON.stringify(queryJson, undefined, 2))
  const { loading, error, data } = useQuery(MAIN_ELASTIC_QUERY, {
    variables: { queryJson, sortJson, size: pageSize, page: offset },
    fetchPolicy: "network-only",
  })
  //console.log("[ELSATIC DATA]: ", data)

  useEffect(() => {
    dispatch({
      type: actionTypes.SET_OFFSET,
      offset: 0,
    })
  }, [pageSize])

  useMemo(() => {
    const onCompleted = data => {
      if (
        typeBucket.length === 0 ||
        authorBucket.length === 0 ||
        languageBucket.length === 0 ||
        libraryCodesBucket.length === 0
      ) {
        dispatch({
          type: actionTypes.ON_SET_BUCKETS,
          authors:
            data.elasticDocuments.documentsPayload.aggregations.author.buckets,
          types:
            data.elasticDocuments.documentsPayload.aggregations.type.buckets,
          languages:
            data.elasticDocuments.documentsPayload.aggregations.languageCode
              .buckets,
          libraries:
            data.elasticDocuments.documentsPayload.aggregations.libraryCode
              .buckets,
        })
      }

      if (data.elasticDocuments) {
        const {
          value,
          relation,
        } = data.elasticDocuments.documentsPayload.hits.total

        setPagination(
          <Pagination
            offset={offset}
            limit={pageSize}
            total={value}
            onClick={(e, offset) => {
              scrollToRef(topContainer)
              dispatch({ type: actionTypes.SET_OFFSET, offset })
            }}
          />
        )

        if (value === 0) {
          setHitsCountElement(
            <Grid item>
              <span>0-0</span>, összesen 0
            </Grid>
          )
        } else if (offset + pageSize > value) {
          setHitsCountElement(
            <Grid item>
              <span>
                {offset === 0 ? 1 : offset}-{value}
              </span>
              , összesen {value}
              {value === 10000 && relation === "gte" ? staticData : null}
            </Grid>
          )
        } else {
          setHitsCountElement(
            <Grid item>
              <span>
                {offset === 0 ? 1 : offset}-{offset + pageSize}
              </span>
              , összesen {value}
              {value === 10000 && relation === "gte"
                ? setIsShowOverflowWarningVisible(true)
                : setIsShowOverflowWarningVisible(false)}
            </Grid>
          )
        }

        const resultElements = data.elasticDocuments.documentsPayload.hits.hits.map(
          docData => {
            return (
              <li key={docData._id} className={searchStyles.searchSingleItem}>
                <ListElement
                  docData={docData}
                  path={props.path}
                  location={props.location}
                />
              </li>
            )
          }
        )

        setSearchResultElements(resultElements)
      } else {
        setHitsCountElement(
          <Grid item>
            <span>0-0</span>, összesen 0
          </Grid>
        )
      }
    }
    const onError = error => {
      console.log(error.message)
      //SNACKBAR
      if (errorModal.errorMessage === null) {
        if (error.graphQLErrors[0]) {
          if (
            error.graphQLErrors[0].extensions.code ===
              "INTERNAL_SERVER_ERROR" &&
            error.message.includes("internal server error")
          ) {
            setErrorModal({
              open: true,
              errorMessage:
                "Az adatlap jelenleg nem elérhető, kérjük próbálja meg később.",
            })
          } else if (
            error.graphQLErrors[0].extensions.code ===
              "INTERNAL_SERVER_ERROR" &&
            error.message.includes("Too many requests")
          ) {
            setErrorModal({
              open: true,
              errorMessage:
                "Ön elérte az egy percen belül indítható maximális bejelentkezési kísérlet számát, kérjük próbálkozzon újra egy perc múlva.",
            })
          }
        } else {
          setErrorModal({ open: true, errorMessage: "Hálózati hiba" })
        }
      }
    }
    if (onCompleted || onError) {
      if (onCompleted && !loading && !error) {
        onCompleted(data)
      } else if (onError && !loading && error) {
        onError(error)
      }
    }
  }, [data, error, loading, pageSize, typeBucket, authorBucket, languageBucket, isShowOverflowWarningVisible])

  const sortTitles = [
    { title: "Relevancia", ref: "relevance" },
    { title: "Szerző", ref: "author" },
    { title: "Cím", ref: "title" },
    { title: "Dátum", ref: "date" },
  ]
  const sortElements = sortTitles.map(title => {
    if (orderBy.activeTitle === title.ref) {
      return (
        <Grid key={title.ref} item md={2} className={styles.sorterItem}>
          <AbcSort sortTitle={title.title} sortRef={title.ref} active={true} />
        </Grid>
      )
    }

    return (
      <Grid key={title.ref} item md={2}>
        <AbcSort sortTitle={title.title} sortRef={title.ref} />
      </Grid>
    )
  })

  const pageTitle = "Keresési találatok"

  const spinner = <CircularProgress color="primary" />

  return (
    <React.Fragment>
      <SubPageLayout>
        <Head title={`${pageTitle}`}/>
        <Grid
          className={styles.mainContainer}
          container
          direction="column"
          justify="flex-start"
          alignItems="stretch"
        >
          <Box className={styles.topContainerSubpage} component="section">
            <Container
              className={styles.topContainerInnerSubpage}
              maxWidth="lg"
            >
              <div ref={topContainer}></div>
            </Container>
          </Box>
          <Box
            className={styles.pageContentContainerSubpageWhite}
            component="section"
          >
            <Container maxWidth="lg">
              <Box
                className={styles.breadcrumbContainerSubpage}
                component="div"
              >
                <Breadcrumbs separator="/" aria-label="breadcrumb">
                  <Link to="/" className={styles.breadcrumbMenuLink}>
                    Főoldal
                  </Link>
                  <span className={styles.breadcrumbMenuNow}>
                    {`${pageTitle}`}
                  </span>
                </Breadcrumbs>
              </Box>
              <Grid
                container
                className={searchStyles.searchstaticDataBlock}
                justify="space-between"
                alignItems="center"
                direction="row"
              >
                <Grid item>
                  Találati lista „
                  <span className={searchStyles.searchWord}>
                    {state.keyword}
                  </span>
                  ” kifejezésre
                </Grid>
                {hitsCountElement}

                <Grid item>
                  <PageSizeSelect />
                </Grid>

                <Grid item xs={12} className={searchStyles.tooMuchDataBlock}>
                  {isShowOverflowWarningVisible ? (
                    <p>{staticTextForOverflowHits}</p>
                  ) : null}
                </Grid>
              </Grid>
              <Grid container>
                <Grid
                  item
                  md={3}
                  className={searchStyles.searchAdvancedElements}
                >
                  <div className={searchStyles.searchAdvancedHeader}>
                    <span>Találat pontosítása</span>
                  </div>
                  <TypeFilterPanel
                    panelTitle="Típus"
                    panelRef="type"
                    bucket={typeBucket}
                  />
                  <TypeFilterPanel
                    panelTitle="Szerző"
                    panelRef="author"
                    bucket={authorBucket}
                  />
                  <PublishYearFilterPanel />

                  <PublishLocationPanel />

                  <TypeFilterPanel
                    panelTitle="Nyelv"
                    panelRef="language"
                    bucket={languageBucket}
                  />

                  <LibraryFilterPanel bucket={libraryCodesBucket} />
                </Grid>
                <Grid item md={9} className={searchStyles.searchResultsList}>
                  <Grid container className={searchStyles.searchFilterLine}>
                    <Grid item md={4}>
                      <span>Lista rendezése</span>
                    </Grid>
                    {sortElements}
                  </Grid>
                  <Box className={searchStyles.searchResultsItems}>
                    <ol></ol>
                    <ol>{loading ? spinner : searchResultElements}</ol>
                  </Box>
                </Grid>
                <Grid item xs={12} className={searchStyles.paginationBox}>
                  <Box className={searchStyles.paginationBoxInner}>
                    <Box className={searchStyles.paginationBoxInnerContent}>
                      {pagination}
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Container>
          </Box>
        </Grid>
      </SubPageLayout>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        open={errorModal.open}
        autoHideDuration={6000}
        onClose={closeErrorModalHandler}
      >
        <ErrorSnackbar
          onClose={closeErrorModalHandler}
          variant="error"
          message={errorModal.errorMessage}
        />
      </Snackbar>
    </React.Fragment>
  )
})

export default ElemetListPage
