import React,{useContext, useState, useMemo} from 'react'

import {
    ExpansionPanel,
    ExpansionPanelSummary,
    ExpansionPanelDetails,
    TextField
} from '@material-ui/core'

import searchStyles from '../../pages/search.module.scss'

import ExpandMoreIcon from "@material-ui/icons/ExpandMore"

import { SearchContextDispatch } from '../../context/search-context/search-context'
import { SearchContextState } from '../../context/search-context/search-context'
import useDidMountEffect from '../../hooks/useDidMountEffect'
import * as actionTypes from '../../context/actionTypes'

const PublishLocationPanel = () => {
    const dispatch = useContext(SearchContextDispatch)
    const state = useContext(SearchContextState)
    
    const [location,setLocation] = useState('')
    const [timer,setTimer] = useState(0)

    const { typeBucket, authorBucket, languageBucket} = state

    useMemo(() => {
        //Reset location filter panel after new search
        setLocation(state.location)
        setTimer(0)
    }, [typeBucket, authorBucket, languageBucket])

    useDidMountEffect(() => {
        clearTimeout(timer)

        setTimer(setTimeout(() => {
            if(location.trim().length > 3){
                dispatch({
                    type: actionTypes.SET_OFFSET,
                    offset: 0
                })
    
                dispatch({
                    type: actionTypes.ON_SEARCHABLE_LOCATION_CHANGE,
                    location
                })
            }
            else if(location.trim() === ''){
                dispatch({
                    type: actionTypes.SET_OFFSET,
                    offset: 0
                })
    
                dispatch({
                    type: actionTypes.ON_SEARCHABLE_LOCATION_CHANGE,
                    location: ''
                })
            }
        }, 2000))
    }, [location])

    return (
        <ExpansionPanel className={searchStyles.searchAdvancedElementsExp} defaultExpanded={true}>
            <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel4a-content"
                id="panel4a-header"
                className={searchStyles.searchAdvancedElementsExpSum}
            >
                Megjelenés helye
            </ExpansionPanelSummary>
            <ExpansionPanelDetails className={searchStyles.searchAdvancedElementsExpDet}>
                <form>
                <TextField
                    id="place"
                    className={searchStyles.textField}
                    margin="none"
                    variant="outlined"
                    type="search"
                    onChange={e => setLocation(e.target.value)}       
                    value={location}             
                />
                </form>
            </ExpansionPanelDetails>
        </ExpansionPanel>
    )
}

export default PublishLocationPanel
