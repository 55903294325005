import React from "react"
import { Link, graphql, useStaticQuery } from "gatsby"
import searchStyles from "../../pages/search.module.scss"

import {
  Grid,
  Box
} from "@material-ui/core";

//components
import HoldingsTable from '../HoldingsTable/HoldingsTable';
import DocumentFuncButtons from '../DocumentFuncButtons/DocumentFuncButtons';

const ListElement = props => {
  const { docData } = props

  const GET_DOCTYPE_DATA_QUERY = graphql`
    query GetDoctypeData {
      allDoctypeMappingCsv {
        nodes {
          hun
          key
          svg
          eng
        }
      }
    }
  `


  const data = useStaticQuery(GET_DOCTYPE_DATA_QUERY)
  const { allDoctypeMappingCsv } = data

  const matchCurrentElement = allDoctypeMappingCsv.nodes.find(node => node.hun === docData._source.type)

  const imageStyle = {
    maxHeight: '100%',
    maxWidth: '100%'
  }

  let renderImage = null
  if (!matchCurrentElement) {
    const elseData = allDoctypeMappingCsv.nodes.find(node => node.key === 'else')
    renderImage = <img src={elseData.svg} alt={elseData.hun} style={imageStyle} />
  }
  else {
    renderImage = <img src={matchCurrentElement.svg} alt={matchCurrentElement.hun} style={imageStyle} />
  }

  const dataToHoldingsTable = [...docData._source.holding];
  for(let i = 0; i <docData._source.quarry.length; i++){
    dataToHoldingsTable[i]={...dataToHoldingsTable[i], quarry: [docData._source.quarry[i]]}
  }
  if(docData._source.quarry.length === 0 && docData._source.holding.length!==0){
    dataToHoldingsTable[0] = {...dataToHoldingsTable[0], quarry: []} 
  }

  return (
    <React.Fragment>
      <Link
        to={`/elements/${docData._id}/${docData._source.originalId}`}
        className={searchStyles.searchSingleItemLink}
        state={{ fromElements: props.path + props.location.search }}
      >
        <Grid container xs={12}>
          <Grid item className={searchStyles.searchSingleItemImgContainer}>
            <Box className={searchStyles.searchSingleItemImgBox}>
              {renderImage}
            </Box>
          </Grid>
          <Grid item className={searchStyles.searchSingleItemContentContainer}>
            <div className={searchStyles.searchSingleItemContent}>
              <div className={searchStyles.searchSingleItemType}>
                <span>{docData._source.type}</span>
              </div>
              <div className={searchStyles.searchSingleItemTitle}>
                <span>{docData._source.title}</span>
              </div>
              <div className={searchStyles.searchSingleItemAuthor}>
                <span>Szerző: </span>{" "}
                <span>{docData._source.author.join(", ")}</span>
              </div>
              <div className={searchStyles.searchSingleItemAppearance}>
                <span>Megjelenés: </span>{" "}
                <span>{docData._source.placeOfAppearance} </span>
                <span>{docData._source.publisher.join(" ")} </span>
                <span>{docData._source.dateOfAppearance}</span>{" "}                
              </div>
              <div className={searchStyles.searchSingleItemSource}>
                <span>Forrás: </span> <span>{docData._source.library}</span> (
              <span>{docData._source.libraryCode}</span>)
            </div>
            </div>
          </Grid>
        </Grid>
      </Link>
      <Grid container direction="row" className={searchStyles.functionContainer}>
        <Grid item className={searchStyles.searchSingleItemImgContainerSpacer} />
        <Grid item className={searchStyles.functionContainerButtons}>
          <DocumentFuncButtons url={`/elements/${docData._id}/${docData._source.originalId}`} data1={docData._source.marc} data2={docData._source.xml} />
        </Grid>
      </Grid>
      <div className={searchStyles.instanceData}>
        <HoldingsTable data={dataToHoldingsTable} fromElementsList />
      </div>
    </React.Fragment>
  )
}

export default ListElement
